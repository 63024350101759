<template>
  <div>
    <v-card v-if="selectedHardware">
      <v-toolbar dark dense fixed color="primary">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Code Generator</v-toolbar-title>
      </v-toolbar>
      <v-select
        v-model="selectedHardware"
        :items="boards"
        hint="Some library include statements, pin assignments and configuration data will change in the code below."
        item-text="name"
        label="Select a board"
        persistent-hint
        return-object
        single-line
        solo
        class="pa-3 ma-3 mb-3"
      ></v-select>
      <v-select
        v-model="selectedTimezone"
        :items="timezones"
        :hint="timezoneDisplay"
        item-text="name"
        label="Select a time zone"
        persistent-hint
        return-object
        single-line
        solo
        class="pa-3 ma-3 mb-3"
      ></v-select>

      <v-list three-line subheader class="mb-3">
        <p class="ma-3 pa-3">
          Customize the variables in this form to enable your
          <strong>{{ selectedHardware.name }}</strong> project to connect to
          your Social Genius API feed.
        </p>

        <h3 class="ma-2 pa-2 primary--text">Optional Settings</h3>
        <v-row>
          <v-col>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>SSID</v-list-item-title>
                <v-list-item-subtitle
                  >Enter your WiFi SSID;
                </v-list-item-subtitle>

                <v-text-field
                  id="ssid-input"
                  v-model="form.ssid"
                  type="text"
                  required
                  placeholder="MyNetwork"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>Data Pin</v-list-item-title>
                <v-list-item-subtitle
                  >Enter the pin location you would like to define as the data
                  pin for the MAX72XX if it's different than what's listed
                  below.
                </v-list-item-subtitle>

                <v-text-field
                  id="data-input"
                  v-model="selectedHardware.pins.digital"
                  type="text"
                  required
                  placeholder="Data Pin: (i.e. D7 or 13)"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>CS Pin</v-list-item-title>
                <v-list-item-subtitle
                  >Enter the pin location you would like to define as the CS pin
                  for the MAX72XX if it's different than what's listed below.
                </v-list-item-subtitle>

                <v-text-field
                  id="CS-input"
                  v-model="selectedHardware.pins.cs"
                  type="text"
                  required
                  placeholder="CS Pin: (i.e. D8 or 14)"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>WiFi Password</v-list-item-title>
                <v-list-item-subtitle
                  >Enter your network password.
                </v-list-item-subtitle>

                <v-text-field
                  id="wifi-input"
                  v-model="form.password"
                  type="password"
                  required
                  placeholder="SuperSecure123!"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>CLK Pin</v-list-item-title>
                <v-list-item-subtitle
                  >Enter the pin location you would like to define as the clock
                  pin for the MAX72XX if it's different than what's listed
                  below.
                </v-list-item-subtitle>

                <v-text-field
                  id="clock-input"
                  v-model="selectedHardware.pins.clk"
                  type="text"
                  required
                  placeholder="Clock Pin: (i.e. D5 or 15)"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-content>
                <v-list-item-title>Transition Duration</v-list-item-title>
                <v-list-item-subtitle
                  >Enter how long you would like each data point to display. Default is 3 second. Fade in for 1.5 seconds and fade out for 1.5 seconds.
                </v-list-item-subtitle>

                <v-text-field
                  v-model="form.duration"
                  hide-details
                  single-line
                  type="number"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <!-- <p class="ma-3 pa-3">
          Connect your computer or cell phone to wifi network
          <strong>HMM_Marquee</strong> without a password (you may or may not be
          prompted). A popup may appear and it will allow you to go to the "Wifi
          config" page. If it does not redirect you, then navigate to
          http://192.168.4.1/wifi and configure it there. Then wait for the
          module to connect to your wifi and take note of the WLAN IP it was
          assigned. Then you can disconnect from the
          <strong>HMM_Marquee</strong> network and return to your regular WLAN.
          Now the ESP8266 is on your network! You can reach it through
          <strong
            ><a
              class="primary--text"
              style="text-decoration: none"
              href="http://marquee.local"
              >http://marquee.local</a
            ></strong
          >. This is a captive portal because through the softAP it will
          redirect any http request to http://192.168.4.1/; pretty cool,
          right?!?
        </p> -->
      </v-list>
      <!-- <v-expansion-panels>
        <v-expansion-panel>
        <v-expansion-panel-header><h3 class="ma-2 pa-2 primary--text">Click Here for Step by Step Instruction:</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
<ol class="ml-6 pa-3">
          <li>
            Configure the <strong>Optional Settings</strong> below if needed.
          </li>
          <li>
            Click the <strong>Copy to Clipboard!</strong> button just above the
            code block, when ready.
          </li>
          <li>
            Paste the code into your Arduino IDE. You may need to
            <i>install the additional Libraries and board configurations</i>
            before continuing.
          </li>
          <li>
            Upload the code to your project board. You've selected the
            <strong>{{ selectedHardware.name }}</strong
            >.
          </li>
          <li>
            Using your phone or computer connect to the
            <strong>HMM_MARQUEE</strong> Wifi. (This is your SG/HMM marquee
            project)
          </li>
          <li>
            Enter Your Wifi Credentials and Project name:
            <strong>{{ getUser.displayName }}</strong
            >.
          </li>
          <li>
            After 10 seconds you should see your SocialGenius.io Project
            information on your HackMakeMod Marquee!
          </li>
          <li>Enjoy!</li>
        </ol>     

        <p class="ml-6 pa-3">
          <strong>***NOTE:</strong> If you ever need to change your project
          settings, you can browse to your marquee using
          <strong
            ><a
              class="primary--text"
              style="text-decoration: none"
              href="http://marquee.local"
              >http://marquee.local</a
            ></strong
          >.
        </p>  
         </v-expansion-panel-content>
      </v-expansion-panel>
         </v-expansion-panels> -->
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-subheader class="primary" dark
          >Output:
          <v-spacer></v-spacer>

          <v-btn
            dark
            text
            @click="closeDialog"
            v-clipboard:copy="arduino"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >Copy to Clipboard!</v-btn
          >

          <!-- <v-btn @click='toggleCode' v-if="!showCode">Show me the code!</v-btn>
            <v-btn @click='toggleCode' v-else>Don't show me the code...</v-btn> -->
        </v-subheader>
      </v-list>
      <h2 class="ma-2 pa-2 primary--text">{{ selectedHardware.name }}</h2>
      <div v-if="selectedTimezone">
      <code-block :code="arduino" :key="componentKey"></code-block>
      </div>
      <p class="grey--text lighten-2 ma-4 pa-4">
        Our Arduino based code uses third party libraries to drive certain
        hardware devices that aren't supported or maintained by us
        (SocialGenius.io). The code that we provide is commented to the best of
        our abilities, but the use of other 3rd party libraries may be required
        to interface with your specific projects setup. We are working with our
        sponsors to provide awesome solutions that work well with our APIs and
        interfaces. However, we are proud to give you an amazing starting point!
        Enjoy!
      </p>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment-timezone";
const _ = require('lodash')

export default {
  name: "CodeGenerator",
  data: () => ({
    timezones: [],
    timezoneDisplay: "",
    daylightSavings: true,
    selectedHardware: null,
    selectedTimezone: null,
    showCode: false,
    boards: [],
    form: {
      ssid: "",
      password: "",
      duration: 3
    },
    componentKey: 0,
  }),
  props: ["close"],
  async mounted() {
    var store = firebase.firestore();
    var zone_name = moment.tz.guess();
    var timezone = moment.tz(zone_name).zoneAbbr();
    // console.log(zone_name, timezone);

    this.timezoneDisplay = `Select your time zone to be used in your marquee. Your detected time zone is ${zone_name} ${timezone}`;

    // this.timezones = moment.tz.zonesForCountry("US", true);
    const countries = moment.tz.countries()
    _.forEach(countries, async country => {
      const ctryTZ = await moment.tz.zonesForCountry(country, true)
      _.forEach(ctryTZ, (tz) =>{
        this.timezones.push(tz)
      })
    })

    const integrations = await store
      .collection("integrations")
      .where("active", "==", true)
      .get();
    await integrations.forEach((doc) => {
      this.boards.push(doc.data());
    });
    this.selectedHardware = this.boards[0];
    this.selectedTimezone = await this.timezones.filter((zone) => {
      return zone.name === zone_name;
    })[0];
  },
  async created() {
    
  },
  methods: {
    updateCodeBlock() {
      this.componentKey += 1;
      this.$forceUpdate();
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    toggleCode() {
      this.showCode = !this.showCode;
    },
    async onCopy(e) {
      // this.snackbar = true;
      var store = firebase.firestore();
      await store.collection("projects").doc(this.getUser.uid).update({
        "projectBoard": this.selectedHardware
      });
      alert(
        `Your ${this.selectedHardware.name} Arduino code was copied to the clipboard!`
      );
    },
    onError: function (e) {
      alert("Failed to copy the text to the clipboard");
      console.log(e);
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        console.log("Form: ", val.password, val.ssid);
        this.form.password = val.password;
        this.form.ssid = val.ssid;
        this.updateCodeBlock();
        this.$nextTick();
      },
    },
    daylightSavings(val) {
      this.daylightSavings = val;
      this.$nextTick();
    },
    selectedHardware: {
      deep: true,
      handler(val) {
        this.selectedHardware = val;
        this.updateCodeBlock();
        this.$nextTick();
      },
    },
    selectedTimezone: {
      deep: true,
      handler(val) {
        this.selectedTimezone = val;
        this.updateCodeBlock();
        this.$nextTick();
      },
    },
    boards(val) {
      this.boards = val;
      this.$nextTick();
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    //     #define CLK_PIN   ${this.selectedHardware.pins.clk}  // or SCK
    // #define DATA_PIN  ${this.selectedHardware.pins.digital}  // or MOSI
    // #define CS_PIN    ${this.selectedHardware.pins.cs}  // or SS
    arduino() {
      return `// ----------------------------
#include <ESP8266WiFi.h>
#include <WiFiClient.h>
#include <ESP8266WebServer.h>

// ----------------------------
// Additional Libraries - each one of these will need to be installed.
// ----------------------------

#include <ArduinoJson.h> // Library used for parsing Json from the API responses
#include <MD_MAX72xx.h>  // Library used for interfacing with the 8x8x4 led matrix
#include <MD_Parola.h>   // Library used for displaying info on the matrix
#include <NTPClient.h>   // Library to get the current time
#include <WiFiUdp.h>     // Library to get the time from a udp server

// Search for "Arduino Json" in the Arduino Library manager
// https://github.com/bblanchon/ArduinoJson


// LED MATRIX DISPLAY DEFINITION
#define HARDWARE_TYPE MD_MAX72XX::FC16_HW
#define MAX_DEVICES  8
#define CLK_PIN   ${this.selectedHardware.pins.clk}  // or SCK
#define DATA_PIN  ${this.selectedHardware.pins.digital}  // or MOSI
#define CS_PIN    ${this.selectedHardware.pins.cs}  // or SS

MD_Parola myDisplay = MD_Parola(HARDWARE_TYPE, DATA_PIN, CLK_PIN, CS_PIN, MAX_DEVICES);
//

const int timeDelay = 3000; // Delay between Time / Day / Subs / Views
const int fadeSpeed = 40;   // Speed of the fade
int i = 0; // looping through the display init at 0
int statusCode;

const int fadeDuration = ${this.form.duration * 1000}/2;

const float numIterations = 15.0;
const int clockedIterations = 50;
const float iterationOffset = (fadeDuration/clockedIterations) * 1.0;

String st;
String content;


enum class State : uint8_t {
  fetch,
  noop,
  wait,
  fadeIn,
  fadeOut
};

enum class Values : uint8_t {
  curTime = 0,
  weekDay = 1,
//  yt_title = 2,
  yt_channel = 2,
  yt_subscribers = 3,
  yt_views = 4,
//   tw_title = 6,
//   tw_account = 7,
//   tw_followers = 8,
//   tw_friends = 9,
//   tw_tweets = 10,
  count = 5 //11
};

State nextState;
State state;

uint32_t prevTime = millis();
uint32_t currentTime = millis();
uint32_t waitDelay;

uint32_t waitDelay_fetch = 5 * 60 * 1000;
//uint32_t waitDelay_fetch = 20 * 1000;

uint32_t prevTime_fetch = millis() + waitDelay_fetch * 2;

String feed[(uint8_t)Values::count] = {};

//YouTube
String youtube_views = "";
String youtube_channel = "";
String youtube_subscribers = "";
String youtube_videos = "";



//Time Variables
WiFiUDP ntpUDP; // Define NTP Client to get time
NTPClient timeClient(ntpUDP, "pool.ntp.org");

String weekDays[7] = {"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"}; //Day Names
char *AMPM = "AM"; // AM / PM // Variable

//-----------------------------
// For HTTPS requests - keep this
WiFiClientSecure client;

// Just the base of the URL you want to connect to
#define SOCIAL_GENIUS_HOST "api.socialgenius.io" //leave this alone


/* Don't set this wifi credentials. They are configurated at runtime and stored on EEPROM */
char ssid[32] = "${this.form.ssid}";
char password[32] = "${this.form.password}";
char project[32] = "${this.getUser.displayName}";


// Web server
ESP8266WebServer server(80);

/* Soft AP network parameters */
IPAddress apIP(192, 168, 4, 1);
IPAddress netMsk(255, 255, 255, 0);


/** Should I connect to WLAN asap? */
boolean connect;

/** Last time I tried to connect to WLAN */
unsigned long lastConnectTry = 0;

/** Current WLAN status */
unsigned int status = WL_IDLE_STATUS;

/** Is this an IP? */
boolean isIp(String str) {
  for (size_t i = 0; i < str.length(); i++) {
    int c = str.charAt(i);
    if (c != '.' && (c < '0' || c > '9')) {
      return false;
    }
  }
  return true;
}

/** IP to String? */
String toStringIp(IPAddress ip) {
  String res = "";
  for (int i = 0; i < 3; i++) {
    res += String((ip >> (8 * i)) & 0xFF) + ".";
  }
  res += String(((ip >> 8 * 3)) & 0xFF);
  return res;
}

void setup() {
  delay(1000);
  Serial.begin(115200);

  //initialize the LED MAtrix 8, 8x8
  myDisplay.begin();
  myDisplay.setIntensity(10);
  myDisplay.setTextAlignment(PA_CENTER);
  myDisplay.setPause(2000);
  myDisplay.setSpeed(40);
  myDisplay.displayClear();
  myDisplay.print("Loading...");

  //Get the local time
  timeClient.begin(); // Initialize NTPClient --> get time
  timeClient.setTimeOffset(${
    this.selectedTimezone ? this.selectedTimezone.offset * 60 * -1 : -14400
  });  // Set offset time in seconds to adjust for your timezone: 3600 sec per Hour
  connect = strlen(ssid) > 0; // Request WLAN connect if there is a SSID
}

void connectWifi() {
  Serial.println("Connecting as wifi client...");
  WiFi.disconnect();
  WiFi.begin(ssid, password);
  int connRes = WiFi.waitForConnectResult();
  client.setInsecure();
  Serial.print("connRes: ");
  Serial.println(connRes);
}

void makeHTTPRequest() {
  Serial.println(project);
  Serial.println(SOCIAL_GENIUS_HOST);
  // Opening connection to server (Use 80 as port if HTTP)
  if (!client.connect(SOCIAL_GENIUS_HOST, 443))
  {
    Serial.println(F("Connection failed"));
    return;
  }

  // give the esp a breather
  yield();

  // Send HTTP request
  client.print(F("GET "));
  // This is the second half of a request (everything that comes after the base URL)
  client.print("/"); // %2C == , mFljAbJMtaaDxSxDN48DuK0hpDc2
  client.print(String(project));
  client.println(F(" HTTP/1.1"));
  Serial.println(client);
  //Headers
  client.print(F("Host: "));
  client.println(SOCIAL_GENIUS_HOST);

  client.println(F("Cache-Control: max-age=0"));


  if (client.println() == 0)
  {
    Serial.println(F("Failed to send request"));
    return;
  }

  // Check HTTP status
  char status[32] = {0};
  client.readBytesUntil('\\r', status, sizeof(status));
  if (strcmp(status, "HTTP/1.1 200 OK") != 0)
    //  {
    //    Serial.print(F("Unexpected response: "));
    //    Serial.println(status);
    //    return;
    //  }

    // Skip HTTP headers
    char endOfHeaders[] = "\\r\\n\\r\\n";
  //  if (!client.find(endOfHeaders))
  //  {
  //    Serial.println(F("Invalid response"));
  //    return;
  //  }


  // peek() will look at the character, but not take it off the queue
  while (client.available() && client.peek() != '{')
  {
    char c = 0;
    client.readBytes(&c, 1);
    Serial.print(c);
    Serial.println("BAD");
  }

  //Use the ArduinoJson Assistant to calculate this:
  DynamicJsonDocument doc(1536); //For ESP32/ESP8266 you'll mainly use dynamic.

  DeserializationError error = deserializeJson(doc, client);

  if (error) {
    Serial.print(F("deserializeJson() failed: "));
    Serial.println(error.f_str());
    return;
  }

  JsonObject youtube = doc["youtube"];
  youtube_views = youtube["views"].as<String>(); 
  youtube_channel = youtube["channel"].as<String>(); 
  youtube_subscribers = youtube["subscribers"].as<String>(); 
  youtube_videos = youtube["videos"].as<String>(); 


  //YouTube
//  feed[(uint8_t)Values::yt_title] = "Youtube";
  feed[(uint8_t)Values::yt_channel] = youtube_channel;


}

char *displaySocialString(const char* string, const char* desc) {
  static char displayString[200] = {""};
  sprintf(displayString, "%s %s", string, desc);
  return displayString;
}



void nextStateAfter(State ss, uint32_t d) {
  waitDelay = d;
  nextState = ss;
  state = State::wait;
  prevTime = currentTime;
}

void statemachine() {
  static float i = 0.0;
  static int textValue = 0;

  switch (state) {
    case State::noop:
      //maybe wait for input? (for now just send anything to serial);
      if (Serial.available() > 0) {
        Serial.readString();
        state = State::fadeIn;
      }
      break;
    case State::fetch:
      if (currentTime - prevTime_fetch > waitDelay_fetch) {
        prevTime_fetch = currentTime;
        makeHTTPRequest();
        Serial.println("Getting Data after 5 Minutes");
      }
      nextStateAfter(State::fadeIn, 50);
      break;
    case State::wait:
      if (currentTime - prevTime > waitDelay) {
        state = nextState;
        prevTime = currentTime;
      }
      break;
    case State::fadeIn:
      //clear the display
      Serial.println(feed[textValue]);
      Serial.println(i);
      Serial.println(numIterations / iterationOffset);

      myDisplay.print(feed[textValue]);
      //set the display intensity

      i += numIterations / iterationOffset;
     

      if (i > numIterations) {
        nextStateAfter(State::fadeOut, clockedIterations);
      } else if (i == 0) {
        myDisplay.displayClear();
      } else {

        nextStateAfter(State::fadeIn, clockedIterations);
        myDisplay.setIntensity(i);
      }
      break;

    case State::fadeOut:

      Serial.println(feed[textValue]);
      //Serial.println(i);
      myDisplay.print(feed[textValue]);

      i -= numIterations / iterationOffset;

      if (i == 0) {
        textValue++;
        if (textValue == (uint8_t)Values::count) {

          textValue = 0;
          myDisplay.displayClear();
          nextStateAfter(State::fetch, 0);
        } else {
          nextStateAfter(State::fadeIn, clockedIterations);
        }
      } else {
        nextStateAfter(State::fadeOut, clockedIterations);
        myDisplay.setIntensity(i);
      }
      break;

  }
}

void loop() {
  currentTime = millis();

  if (connect) {
    Serial.println("Connect requested");
    connect = false;
    connectWifi();
    lastConnectTry = millis();
  }
  {
    unsigned int s = WiFi.status();
    if (s == 0 && millis() > (lastConnectTry + 60000)) {
      /* If WLAN disconnected and idle try to connect */
      /* Don't set retry time too low as retry interfere the softAP operation */
      connect = true;
    }
    if (status != s) { // WLAN status change
      Serial.print("Status: ");
      Serial.println(s);
      status = s;
      if (s == WL_CONNECTED) {
        /* Just connected to WLAN */
        Serial.println("");
        Serial.print("Connected to ");
        Serial.println(ssid);
        Serial.print("IP address: ");
        Serial.println(WiFi.localIP());

      } else if (s == WL_NO_SSID_AVAIL) {
        WiFi.disconnect();
      }
    }
    if (s == WL_CONNECTED) {
      timeClient.update();

    }
  }
  // Do work:
  configureData();
  //STATE
  statemachine();

  //HTTP
  server.handleClient();
}


void configureData() {
  timeClient.update();

  int currentHour = timeClient.getHours();
  int currentMinute = timeClient.getMinutes();
  //AM or PM adjustment
  if (currentHour >= 12)
  {
    AMPM = "PM";
  }
  else(AMPM = "AM");
  if (currentHour >= 13) currentHour = currentHour - 12;
  if (currentHour == 0) currentHour = 12;

  String weekDay = weekDays[timeClient.getDay()];
  //  Serial.println(weekDay);

  char time[40];
  sprintf(time, "%d:%i %s", currentHour, currentMinute, AMPM);

  if (currentMinute < 10) //Adds a leading 0 before a single digit minute
  {
    sprintf(time, "%d:0%i %s", currentHour, currentMinute, AMPM);
  }
  else sprintf(time, "%d:%i %s", currentHour, currentMinute, AMPM);
  //  Serial.println(time);

  float subs = (youtube_subscribers.toFloat());
  float subDec;
  char subTrunc[30];
  String subEnd = "Subs";

  if (subs >= 1000000.0) {
    subDec = (subs) / 1000000.0;
    subEnd = "M Subs";
    sprintf(subTrunc, "%4.2f%s", subDec, subEnd);
  }
  else if (subs >= 10000.0 && subs <= 1000000.0) {
    subDec = (subs) / 1000.0;
    subEnd = "K Subs";
    sprintf(subTrunc, "%4.0f%s", subDec, subEnd);
  }
  else if (subs >= 1.0) {
    subDec = (subs);
    subEnd = " Subs";
    sprintf(subTrunc, "%4.0f%s", subDec, subEnd);
  }
//  Serial.println("Subs: ");
//  Serial.println(subTrunc);

  float views = (youtube_views.toFloat());
  float viewDec;
  char viewTrunc[30];
  String viewEnd = "Views";

  if (views >= 10000000.0) {
    viewDec = (views) / 1000000.0;
    viewEnd = "M Views";
    sprintf(viewTrunc, "%4.0f%s", viewDec, viewEnd);
  }
  else if (views >= 1000000.0) {
    viewDec = (views) / 1000000.0;
    viewEnd = "M Views";
    sprintf(viewTrunc, "%4.2f%s", viewDec, viewEnd);
  }
  else if (views >= 10000.0 && views <= 1000000.0) {
    viewDec = (views) / 1000.0;
    viewEnd = "K Views";
    sprintf(viewTrunc, "%4.2f%s", viewDec, viewEnd);
  }
  else if (views >= 1000.0) {
    viewDec = (views);
    viewEnd = " Views";
    sprintf(viewTrunc, "%4.0f%s", viewDec, viewEnd);
  }
  //  Serial.println(viewTrunc);



// 
  myDisplay.setTextAlignment(PA_CENTER);

  feed[(uint8_t)Values::curTime] = time;
  feed[(uint8_t)Values::weekDay] = weekDay;
  //YouTube
  feed[(uint8_t)Values::yt_views] = viewTrunc;
  feed[(uint8_t)Values::yt_subscribers] = subTrunc;

}`;
    },
  },
};
</script>

<style scoped>
pre:not(:last-of-type) {
  margin-bottom: 2rem;
}
</style>