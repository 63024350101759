import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import router from "@/router";
import { setUser, destroyUser } from "@/services/jwt.service";

export default {
  register({ commit }, payload) {
    commit("setLoading", true);

    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.userName, payload.password)
      .then(response => {
        response.user
          .updateProfile({
            displayName: payload.projectName
          })
          .then(async () => {
            setUser(firebase.auth().currentUser);
            //set a project 
            var store = firebase.firestore();
            store.collection("projects").doc(firebase.auth().currentUser.uid).set({
              user: firebase.auth().currentUser.uid,
              Youtube: null,
              Twitter: null,
              Facebook: null,
              Instagram: null,
              TikTok: null,
              Stocks: [],
              projectName: payload.projectName,
              updated: firebase.firestore.FieldValue.serverTimestamp(),
              api: {weather: null},
              settings: {
                youtube: {
                  getLatestVideo: false
                },
                weather: {
                  includeWeather: false
                }
              }
            }).then(() => {
              router.push("/");
            })

          });
      })
      .catch(error => commit("setErrors", error))
      .finally(() => commit("setLoading", false));
  },

  login({ commit }, payload) {
    commit("setLoading", true);

    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then(response => {
        setUser(response.user);
        response.user.getIdTokenResult().then(token => {
          response.user.admin = token.claims.admin;
          if (token.claims.admin) {
            router.push("/admin");
          } else {
            router.push("/");
          }
        });
      })
      .catch(error => commit("setErrors", error))
      .finally(() => commit("setLoading", false));
  },

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => router.push("/login"));

    destroyUser();
  },

  addAdminRole({ commit }, email) {
    commit();
    const addAdminRole = firebase.functions().httpsCallable("addAdminRole");
    addAdminRole({ email });
  }
};
