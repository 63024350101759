<template>
  <div class="ma-3 pa-3 scrollable pre-formatted">
    <pre v-highlightjs><code class="cpp">{{code}}</code></pre>
  </div>
</template>

<script>
export default {
  name: "CodeBlock",
  props: ["code"],
  watch: {
    code(val) {
      this.code = val;
    //   console.log('FROM KID: ', val)
    //   this.$nextTick();
    },
  },
};
</script>

<style>
.scrollable {
     width: 95%;
  height: 400px;
  overflow: scroll;
  
}
.pre-formatted {
  white-space: pre-wrap;
}
</style>