<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <span v-if="getLoginStatus" class="secondary--text">{{getUser.displayName}}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="getLoginStatus" class="primary--text">Welcome</span><span v-else>...Login to contiue!</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="getLoginStatus" @click="logout">
          <v-list-item-icon>
            <v-icon color="primary">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
          </v-list-item> 
          <span v-else>
<v-list-item to="/register">
          <v-list-item-icon>
            <v-icon color="primary">mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Register</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          <v-list-item  to="/login">
          <v-list-item-icon>
            <v-icon color="primary">mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          </span>
      </v-list>
      <v-footer
    color="primary lighten-1"
    padless
    bottom
  >
  <v-btn text dark block to="/contact">Contact Support</v-btn>
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark absolute
      shrink-on-scroll
      position="fixed"
      prominent
      src="./assets/marquee_HMM.jpg"
      fade-img-on-scroll
      >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      
  <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(17,107,99,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      
    </v-app-bar>
   

    <v-main>
      
      <router-view />
    </v-main>
   
  </v-app>
  
</template>

<script>
import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
  export default {
    data: () => ({ 
      drawer: null,
      items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
          { title: 'About', icon: 'mdi-help-box', to: '/about' },
        ],

    }),
    created() {

    firebase
      .auth()
      .onAuthStateChanged(user =>
        this.$store.commit("setLoginStatus", user !== null)
      );
  },
  methods: {
     logout() {
      this.$store.dispatch("logout");
    }
  },
  mounted() {
    document.title = 'Social Genius - The easy API and code generator!'
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus"])
  }
  }
</script>
