import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            // primary: '#04504a',
            primary: '#4c8a85',
            // primary: '#d85617',
            secondary: '#33364D',
            anchor: '#8c9eff',
          },
        },
      },
});
