import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import "./components/vue-components";
// import './firebaseApp'
import vuetify from './plugins/vuetify'

import VueHighlightJS from 'vue-highlightjs'
import javascript from 'highlight.js/lib/languages/javascript';
import cpp from 'highlight.js/lib/languages/cpp';
import 'highlight.js/styles/arduino-light.css';
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)


 Vue.use(VueHighlightJS, {
	// Register only languages that you want
	languages: {
		cpp,
		javascript,
		// vue
	}
})

Vue.config.productionTip = false

const env = process.env;

const firebaseConfig = {
  apiKey: env.VUE_APP_API_KEY,
  authDomain: env.VUE_APP_AUTH_DOMAIN,
  databaseURL: env.VUE_APP_DATABASE_URL,
  projectId: env.VUE_APP_PROJECT_ID,
  storageBucket: env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
  appId: env.VUE_APP_APP_ID,
  measurementId: env.VUE_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

let app = "";

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
});
