<template>
  <v-btn type="submit" :variant="variant" :disabled="loading">
    <template v-if="loading">
      <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
      Loading...
    </template>
    <template v-else>{{ text }}</template>
  </v-btn>
</template>

<script>
export default {
  name: "LoadingButton",
  props: {
    variant: {
      type: String,
      default: "primary"
    },
    loading: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
