export default [
  {
    path: "*",
    redirect: "/login"
  },
  {
    name: "Home",
    path: "/",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
    meta: {
      requiresAuth: true
    }
  },
//   {
//     name: "admin",
//     path: "/admin",
//     component: () => import(/* webpackChunkName: "home" */ "@/views/admin"),
//     meta: {
//       requiresAuth: true
//     }
//   },
  {
    name: "Login",
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/Auth/Login")
  },
  {
    name: "About",
    path: "/about",
    component: () =>
      import(/* webpackChunkName: "About" */ "@/views/About"),
      meta: {
        requiresAuth: true
      }
  },
  {
    name: "Contact",
    path: "/contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "@/views/Contact"),
      meta: {
        requiresAuth: true
      }
  },
  {
    name: "Register",
    path: "/register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "@/views/Auth/Register")
  }
];
